// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";

import { skills, ISkill } from "../../config/index";
import BackIcon from "../../assets/icons/back.svg";
import Icon from "../../assets/skills/Icon";
import { paths } from "../../assets/skills/index";

import useStyles from "./style";

export default function Skills() {
  const classes = useStyles();
  const skillsHTML = `<div
  style="border: 1px solid lightgray; border-radius: 10px; padding: 15px;"
  class="skills"
>
  <p style="font-size: 14px;">
    Some technologies that I use or have worked with in the past:
  </p>

  <h4>Programming Languages</h4>

  <table style="border: none !important; font-size: 12px;">
    <tr style="border: none !important; font-size: 12px;">
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          alt="JS"
          title="JavaScript"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/javascript/javascript.png"
        />
        <br />
        JavaScript
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Python"
          alt="Python"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/python/python.png"
        />
        <br />
        Python
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          alt="Typescript"
          title="Typescript"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/main/topics/typescript/typescript.png"
        />
        <br />
        Typescript
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Java"
          alt="Java"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/java/java.png"
        />
        <br />
        Java
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="C"
          alt="C"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/c/c.png"
        />
        <br />C
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          alt="HTML5"
          title="HTML5"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/html/html.png"
        />
        <br />
        HTML5
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          alt="CSS3"
          title="CSS3"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/css/css.png"
        />
        <br />
        CSS3
      </td>
    </tr>
  </table>

  <h4>Frameworks and Libraries</h4>

  <table style="border: none !important; font-size: 12px;">
    <tr style="border: none !important; font-size: 12px;">
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="React"
          alt="React"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/react/react.png"
        />
        <br />
        React
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Next.js"
          alt="Next.js"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/nextjs/nextjs.png"
        />
        <br />
        Next.js
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Node.js"
          alt="Node.js"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/nodejs/nodejs.png"
        />
        <br />
        Node.js
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Flask"
          alt="Flask"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/flask/flask.png"
        />
        <br />
        Flask
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="jQuery"
          alt="jQuery"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/jquery/jquery.png"
        />
        <br />
        jQuery
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Selenium"
          alt="Selenium"
          width="60px"
          src="https://img.icons8.com/color/48/000000/selenium-test-automation.png"
        />
        <br />
        Selenium
      </td>
    </tr>
  </table>

  <h4>Cloud and Database Technologies</h4>

  <table style="border: none !important; font-size: 12px;">
    <tr style="border: none !important; font-size: 12px;">
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="AWS"
          alt="AWS"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/main/topics/aws/aws.png"
        />
        <br />
        AWS
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Azure"
          alt="Azure"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/main/topics/azure/azure.png"
        />
        <br />
        Azure
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="SQL"
          alt="SQL"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/sql/sql.png"
        />
        <br />
        SQL
      </td>
    </tr>
  </table>

  <h4>Tools</h4>

  <table style="border: none !important; font-size: 12px;">
    <tr style="border: none !important; font-size: 12px;">
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Git"
          alt="Git"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/git/git.png"
        />
        <br />
        Git
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="GitHub"
          alt="GitHub"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/github/github.png"
        />
        <br />
        GitHub
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Jira"
          alt="Jira"
          width="60px"
          src="https://img.icons8.com/color/48/000000/jira.png"
        />
        <br />
        Jira
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="VS Code"
          alt="VS Code"
          width="60px"
          src="https://img.icons8.com/fluent/48/000000/visual-studio-code-2019.png"
        />
        <br />
        VS Code
      </td>
      <td
        align="center"
        style="border: none !important; font-size: 12px;"
      >
        <img
          title="Jupyter Notebook"
          alt="Jupyter"
          width="60px"
          src="https://raw.githubusercontent.com/github/explore/master/topics/jupyter-notebook/jupyter-notebook.png"
        />
        <br />
        Jupyter Notebook
      </td>
    </tr>
  </table>
</div>`;

  return (
    <div className={classes.root}>
      <Link className={classes.back} to="/?page=skills">
        <img className={classes.backIcon} src={BackIcon} />
      </Link>

      <div className={classes.headerWrapper}>
        <h1 className={classes.title}>Skills</h1>
      </div>

      <span className={classes.mainDivider} />

      {/* {skills.map((skill) => (
        <p>skill</p>
      ))} */}

      <div
        dangerouslySetInnerHTML={{ __html: skillsHTML }}
        style={{ color: "white" }}
      ></div>
    </div>
  );
}
