export interface IProject {
  title: string,
  url: string,
  github: string,
  description: Array<string>
  image: string,
  topics: Array<string>
  tools: Array<string>
  start: number,
  end: Number,
  npm: string | null,
}

const projects: IProject[] = [
  {
    title: 'CapAir',
    url: 'https://tinyurl.com/flycapair',
    github: 'https://github.com/enfuka/CapAir',
    description: [
      'This is a simulated project that I was a part of during my summer internship. 95% of the front-end is written by me, I also contributed to the backend as well as the deployment process on AWS.',
      'The goal of this project was to create a full-stack web application that would allow users to search for flights and book them. The front-end is written in React.js, the backend is written in Java using Spring Boot, and the database is hosted on AWS RDS.',
      "Currently I have the project deployed on Azure and it's fully functional",
    ],
    image: 'capair',
    topics: [
      'Airline',
      'Web Development',
    ],
    tools: [
      'React.js',
      'Spring Boot',
      'AWS',
      'Python',
      'Material UI'
    ],
    start: 1641456000000,
    end: 1642406400000,
    npm: null,
  },
  {
    title: 'VCU Carpool',
    url: 'https://tinyurl.com/vcucarpool',
    github: 'https://github.com/enfuka/VCU-Carpool',
    description: [
      "A proof of concept full-stack Web application that facilitates ridesharing around the campus, created using React and Next.js",
      "This was a passion project of mine to learn React and Next.js as well as to design a proper relational database schema.",
      "I also learned a lot about the Google Maps API and how to use it to create a custom map with custom markers and info windows.",
      "I was able to make it functional enough to be used by a small group of people, but I didn't have the time to make it production ready.",
      "If you would like to try it out, you can use the following credentials: ",
      "Username: test@vcu.edu",
      "Password: test",
      
    ],
    image: 'vcucarpool',
    topics: [
      'Transportation',
      'Utilities',
    ],
    tools: [
      'React.js',
      'Next.js',
      'Google Maps API',
    ],
    start: 1622530800000,
    end: 1622876400000,
    npm: null,
  },
  {
    title: 'enfuka.com',
    url: 'https://enfuka.com',
    github: '',
    description: [
      'Portfolio site, built with React.js and Typescript to demonstrate proficiency, as well as visually showcase examples of work.',
      'I haven\'t had much time to work on this site, but I plan on adding more features in the future.',
      'I also need to clean up the code and make it public.',
    ],
    image: 'enfuka',
    topics: [
      'Portfolio',
      'Personal'
    ],
    tools: [
      'React.js',
      'Typescript',
      'Material UI'
    ],
    start: 1595833200000,
    end: 1606118400000,
    npm: null,
  },
  {
    title: 'Pink Finance',
    url: '',
    github: 'https://github.com/enfuka/Pink-Finance',
    description: [
      'Mock stock trading website built using Flask, Bootstrap and jQuery.',
      'One of my first proper full-stack websites.',
      'It shows live stock prices and allows users to buy and sell stocks using fake money.',
      "Quite bare bones, but it was a great learning experience for me and I'm proud of it.",
    ],
    topics: [
      'Finance',
      'Utilities',
    ],
    image: 'finance',
    tools: [
      'Flask',
      'jQuery',
      'Bootstrap',
    ],
    start: 1566630000000,
    end: 1567839600000,
    npm: null,
  },
];

export default projects;
