export interface IJob {
  title: string,
  company: string,
  location: string,
  start: number,
  end: number,
  description: string[],
  achievements: string[],
  topics: string[],
  type: string,
  link?: string,
}

const jobs: Array<IJob> = [
  {
    title: 'Software Engineer / Consultant Intern',
    link: 'https://business.adobe.com/products/target/adobe-target.html',
    company: 'CapTech',
    location: 'Richmond, VA',
    start: 1682999200000,
    end: 1690959000000,
    description: [
      `Spearheaded the development of an airline website and a booking system with an autonomous consulting team.`,
      `Engineered a user-centric, responsive, and accessible front-end solution using React.`,
      `Gained hands-on experience in requirements gathering, UI and API development, data analysis, and Amazon Web Services (AWS) utilization by creating a full-stack application deployed on the cloud.`,
      `Proficiently applied agile methodologies throughout the project lifecycle, enhancing adaptability and project management skills.`,
      
    ],
    achievements: [],
    topics: [
      'React.js',
      'Web Development',
      'Coding',
      'Agile',
      'AWS',
      'Consulting',
    ],
    type: 'Internship',
  },
  {
    title: 'Learning Ambassador',
    company: 'Amazon',
    location: 'Ashland, VA',
    start: 1589945800000,
    end: 1674192900000,
    description: [
      'Independently trained two to ten new hires at a time on warehouse operations and was responsible for them throughout the training process',
    ],
    achievements: [],
    topics: ['Leadership', 'Training', 'Communication', 'Teamwork'],
    type: 'Job',
  },
];

export default jobs;
