import React from 'react';
import {
  AppBar as MaterialAppBar,
  Tooltip,
} from '@material-ui/core';
import { Link } from "react-router-dom";

import SourceCodeIcon from '../../../assets/icons/sourcecode.svg';
import GithubIcon from '../../../assets/icons/github.svg';
import LinkedInIcon from '../../../assets/icons/linkedin.svg';
import SpotifyIcon from '../../../assets/icons/spotify.svg';

import useStyles from './style';

function AppBar() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <MaterialAppBar
        color="transparent"
        position="fixed">
        <div className={classes.toolBar}>
          <Link
            className={classes.title}
            to="/">
            Enes Kalinsazlioglu
          </Link>
          <div className={classes.iconWrapper}>
            <Tooltip title="LinkedIn">
              <span>
                <a
                  href="https://www.linkedin.com/in/ekalinsaz/"
                  target="_blank"
                  rel="noreferrer">
                  <img
                    className={classes.icon}
                    src={LinkedInIcon}
                    alt="ekalinsaz"/>
                </a>
              </span>
            </Tooltip>
            <Tooltip title="Github">
              <span>
                <a
                  href="https://github.com/enfuka"
                  target="_blank"
                  rel="noreferrer">
                  <img
                    className={classes.icon}
                    src={GithubIcon}
                    alt="enfuka"/>
                </a>
              </span>
            </Tooltip>
            </div>
          </div>
      </MaterialAppBar>
    </React.Fragment>
  )
}

export default AppBar;
